<template>
    <transition name="opacity" mode="out-in">
        <div v-if="!send" class="feedback "
             :class="{'feedback_progress': progress}">

            <div v-if="progress" class="preloader"></div>

            <div v-if="modal" class="feedback__header">
                <div v-if="title" class="feedback__title">{{ title }}</div>
                <div id="captcha-container"></div>

                <v-specialist v-if="specialist"
                              :item="specialist"
                              class="feedback__specialist">
                </v-specialist>

                <div v-if="service" class="feedback__service">{{ service.name }}</div>

                <v-price v-if="specialist || offer" :specialist="specialist"
                         :offer="offer"
                ></v-price>
            </div>

            <div class="feedback__body"
                 :class="{'feedback__body_gray': modal}"
                 :style="{'opacity': progress}">
                <div v-if="reception" class="feedback__reception">
                    <v-reception class="feedback__datetime"></v-reception>
                </div>

                <div class="feedback__information">
                    <v-form
                        class="feedback__form"
                        :form="form"
                        :submitBtn="false"
                        @onSubmit="onSubmit"
                        url=""></v-form>
                </div>
            </div>

            <div class="feedback__footer" :class="{'feedback__footer_compact': !modal}">
                <div class="feature feedback__date">
                    <div class="feature__label">Дата и врем приема</div>
                    <div class="feature__value">
                        <span>{{ status }}</span>
                    </div>
                </div>

                <v-button class="btn_primary btn_lg"
                          @onClick="onSubmit"
                          :disabled="!form.valid">
                    Записатся
                </v-button>
            </div>
        </div>

        <v-thanks v-else class="thanks_order"
                  title="Спасибо что обратились к нам!"
                  description="Мы свяжемся с вами для уточнения деталей"
        ></v-thanks>
    </transition>
</template>

<script>
import {computed, onMounted, ref} from 'vue';
import {reactive} from "@vue/reactivity";
import {useStore} from "vuex";

import Form from "@components/ui/forms/Form";
import Button from "@components/ui/forms/Button";
import Thanks from "@components/feedback/Thanks";
import Specialist from "@components/specialist/Specialist";
import Price from "@components/services/Price";
import Reception from "@components/order/Reception";

import {email, maxLength, minLength, required} from "@utils/validators";

import useForm from "@use/form";
import useTemplate from "@use/native/template";
import usePhoneMask from "@use/native/phoneMask";
import useOrdering from "@use/data/feedback/ordering";

export default {
    name: "Ordering",
    components: {
        "v-form": Form,
        "v-button": Button,
        "v-thanks": Thanks,
        "v-specialist": Specialist,
        "v-price": Price,
        "v-reception": Reception,
    },
    props: {
        title: {
            type: String
        },
        dataset: {
            type: Object
        },
        resetTimeOut: {
            type: Number,
            default: 5000
        },
        modal: {
            type: Boolean,
            default: false,
        }
    },
    setup(props) {
        const store = useStore();
        let date = ref(null);
        let clientKey = ref(null);

        onMounted(() => {
            let recaptcha = document.getElementById("g-recaptcha");
            if (recaptcha) {
                clientKey.value = recaptcha.dataset?.clientkey ?? null;
            }
        })

        let data = reactive(props.dataset);
        let reception = ref(false);
        let keys = reactive([]);
        let send = ref(false);

        let specialistID = null,
            serviceID = null,
            offerID = null,
            specialist = null,
            service = null,
            offer = null;

        if (props.modal) {
            specialistID = computed(() => store.getters['ordering/specialistID'])
            serviceID = computed(() => store.getters['ordering/serviceID'])
            offerID = computed(() => store.getters['ordering/offerID'])

            specialist = computed(() => store.getters['ordering/specialist'])
            service = computed(() => store.getters['ordering/service'])
            offer = computed(() => store.getters['ordering/offer'])
        } else {
            specialistID = ref(null);
            serviceID = ref(null);
            offerID = ref(null);
        }

        let progress = ref(false);

        let status = computed(() => {
            return `На ближайшее время`
        })

        onMounted(() => {
            data = props.dataset;
        });

        function onSubmit() {
            console.log('onSubmit');
            console.log(data, props.dataset);

            let ctx = {};
            keys = Object.keys(form.fields);
            keys.map((key) => {
                ctx[key] = form.fields[key].value
            })

            ctx['specialist_id'] = specialistID.value;
            ctx['service_id'] = serviceID.value;
            ctx['offer_id'] = offerID.value;
            ctx['action_id'] = data.action;
            ctx['checkup_id'] = data.checkup;
            ctx['near'] = false;

            console.log(ctx);
            submit(ctx);
        }

        async function submitting(data) {
            progress.value = true;
            const {fetchData} = useOrdering(data);
            let result = await fetchData();
            console.log(result);
            progress.value = false;
            send.value = true;

            if (result.status === 'ok') {
                try {
                    window.yaCounter55618591.reachGoal('forma');
                    window._tmr.push({ type: 'reachGoal', id: 3340607, goal: 'fos-vk'});
                } catch (e) {
                    console.log(e)
                }
            }

            if (result.status === 'ok') {
                setTimeout(() => {
                    if (send.value === true) {
                        send.value = false;
                        reset();
                    }
                }, props.resetTimeOut);
            } else if ('detail' in result) {
                send.value = false;
                form.errors = result?.detail[0]?.msg ?? null;
            } else {
                send.value = false;
                form.errors = result.error;
            }
        }

        async function submit(data) {
            if (clientKey.value) {
                // recaptcha v3
                window.grecaptcha.ready(async function () {
                    window.grecaptcha
                        .execute(clientKey.value, {action: 'submit'})
                        .then(async function (token) {
                            await submitting({...data, token});
                        });
                });

                // recaptcha enterprise
                // window.grecaptcha.enterprise.ready(async () => {
                //     const token = await window.grecaptcha.enterprise.execute(clientKey.value, {action: 'LOGIN'});
                //     console.log(token)
                //     await submitting({...data, token});
                //     // See https://cloud.google.com/recaptcha-enterprise/docs/create-assessment
                // });
            } else {
                await submitting(data);
            }
        }


        const form = useForm({
            captcha: true,
            title: null,
            description: null,
            field: {
                name: {
                    name: 'name',
                    label: 'Ф.И.О.',
                    value: '',
                    required: true,
                    type: 'phone',
                    clear: true,
                    validators: {
                        required: {
                            validator: required,
                            message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                        },
                    },
                },
                phone: {
                    name: 'phone',
                    label: 'Телефон',
                    value: '',
                    type: 'tel',
                    required: true,
                    clear: true,
                    mask: usePhoneMask,
                    validators: {
                        required: {
                            validator: required,
                            message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                        },
                        minLength: {
                            validator: minLength(9),
                            pattern: '',
                            value: 9,
                            message: useTemplate`${'label'} - Слишком короткое поле ${'length'} ${'value'}`,
                        },
                        maxLength: {
                            validator: maxLength(20),
                            pattern: '',
                            value: 20,
                            message: useTemplate`${'label'} - Слишком длинное поле ${'length'} ${'value'}`,
                        },
                    },
                },
                email: {
                    name: 'email',
                    label: 'Email',
                    value: '',
                    type: 'email',
                    clear: true,
                    validators: {
                        required: {
                            validator: email,
                            message: useTemplate`Не верный формат ${'label'}`,
                        },
                    },
                },
            }
        })

        function reset() {
            Object.keys(form.fields).map((key) => {
                form.fields[key].value = ''
                form.fields[key].status = false;
            })
        }

        // if (window.smartCaptcha) {
        //     window.smartCaptcha.render('captcha-container', {
        //         sitekey: 'ysc1_THcWOmMrTTTqPBEhEUvyyZPen8mlkWpds4ZeKSgS365ea03d',
        //         invisible: true,
        //         callback: callback,
        //         hideShield: true,
        //     });
        // }

        function click_btn() {
            // e.preventDefault();

        }

        return {
            click_btn,
            send,

            service,
            specialist,
            offer,

            progress,
            date,
            data,
            reception,
            status,
            form,

            onSubmit,
        }
    }
}
</script>

<style></style>

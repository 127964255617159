<template>
    <transition name="opacity" mode="out-in">
        <div v-if="!send" class="feedback "
             :class="{'feedback_progress': progress}">

            <div v-if="progress" class="preloader"></div>

            <div class="feedback__body"
                 :class="{'feedback__body_gray': modal}"
                 :style="{'opacity': progress}">

                <v-form
                    class="feedback__form"
                    :form="form"
                    :submitBtn="false"
                    @onSubmit="onSubmit"
                    url=""></v-form>
            </div>

            <div class="feedback__footer" :class="{'feedback__footer_compact': !modal}">
                <v-button class="btn_primary btn_lg"
                          @onClick="onSubmit"
                          :disabled="!form.valid">Отправить
                </v-button>
            </div>
        </div>

        <v-thanks v-else class="thanks_order"
                  title="Спасибо что обратились к нам!"
                  description="Мы свяжемся с вами для уточнения деталей"
        ></v-thanks>
    </transition>
</template>

<script>
import {onMounted, ref} from 'vue';
import {reactive} from "@vue/reactivity";

import Form from "@components/ui/forms/Form";
import Button from "@components/ui/forms/Button";
import Thanks from "@components/feedback/Thanks";

import {integer, maxLength, minLength, required} from "@utils/validators";


import useForm from "@use/form";
import useTemplate from "@use/native/template";
import usePhoneMask from "@use/native/phoneMask";
import useTaxDeduction from "@use/data/feedback/tax_deduction";

export default {
    name: "TaxDeduction",
    components: {
        "v-form": Form,
        "v-button": Button,
        "v-thanks": Thanks,
    },
    props: {
        title: {
            type: String
        },
        dataset: {
            type: Object
        },
        resetTimeOut: {
            type: Number,
            default: 5000
        },
        modal: {
            type: Boolean,
            default: false,
        }
    },
    setup(props) {
        // const store = useStore();
        let date = ref(null);
        let clientKey = ref(null);

        onMounted(() => {
            let recaptcha = document.getElementById("g-recaptcha");
            if (recaptcha) clientKey.value = recaptcha.dataset?.clientkey ?? null;
        })

        let data = reactive(props.dataset);
        let keys = reactive([]);
        let send = ref(false);

        let progress = ref(false);

        onMounted(() => {
            data = props.dataset
        });

        function onSubmit() {
            let ctx = {};
            keys = Object.keys(form.fields);
            keys.map((key) => {
                ctx[key] = form.fields[key].value
            })
            submit(ctx);
        }

        async function submitting(data) {
            progress.value = true;
            const {fetchData} = useTaxDeduction(data);
            let result = await fetchData();
            progress.value = false;
            send.value = true;

            if (result.status === 'ok') {
                setTimeout(() => {
                    if (send.value === true) {
                        send.value = false;
                        reset();
                    }
                }, props.resetTimeOut);
            } else if ('detail' in result) {
                send.value = false;
                form.errors = result?.detail[0]?.msg ?? null;
            } else {
                send.value = false;
                form.errors = result.error;
            }
        }

        async function submit(data) {
            if (clientKey.value) {
                window.grecaptcha.ready(async function () {
                    window.grecaptcha
                        .execute(clientKey.value, {action: 'submit'})
                        .then(async function (token) {
                            await submitting({...data, token});
                        });
                });
            } else {
                await submitting(data);
            }
        }

        const form = useForm({
            captcha: true,
            title: 'Заявление о выдаче справки об оплате медицинских услуг',
            description: null,
            groups: [
                {
                    direct: 'column',
                    label: 'Данные налогоплательщика',
                    field: {
                        payer: {
                            name: 'payer',
                            label: 'ФИО плательщика/заявителя',
                            value: '',
                            required: true,
                            type: 'text',
                            clear: true,
                            validators: {
                                required: {
                                    validator: required,
                                    message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                                },
                            },
                        },

                        year_of_birth_payer: {
                            name: 'year_of_birth_payer',
                            label: 'Год рождения плательщика',
                            value: '',
                            required: true,
                            type: 'date',
                            clear: true,
                            validators: {
                                required: {
                                    validator: required,
                                    message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                                },
                            },
                        },

                        INN: {
                            name: 'INN',
                            label: 'ИНН',
                            value: '',
                            required: true,
                            type: 'text',
                            clear: true,
                            validators: {
                                required: {
                                    validator: required,
                                    message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                                },
                                minLength: {
                                    validator: minLength(10),
                                    value: 10,
                                    message: useTemplate`Слишком короткое поле ${'length'}/${'value'}`,
                                },
                                maxLength: {
                                    validator: maxLength(12),
                                    value: 12,
                                    message: useTemplate`Слишком длинное поле ${'length'}/${'value'}`,
                                },
                                integer: {
                                    validator: integer,
                                    message: useTemplate`${'label'} - Должен быть числом`,
                                },
                            },
                        },

                        seria_pass_payer: {
                            name: 'seria_pass_payer',
                            label: 'Cерия и номер паспорта',
                            value: '',
                            required: true,
                            type: 'text',
                            help: 'Пример: 61 23 234567',
                            clear: true,
                            validators: {
                                required: {
                                    validator: required,
                                    message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                                },
                            },
                        },

                        year_pass_payer: {
                            name: 'year_pass_payer',
                            label: 'Дата выдачи паспорта',
                            value: '',
                            required: true,
                            type: 'date',
                            clear: true,
                            validators: {
                                required: {
                                    validator: required,
                                    message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                                },
                            },
                        },
                    },
                }, {
                    label: 'Данные пациента',
                    description: 'Заполняются, если налогоплательщик и<br/> пациент <strong>НЕ ЯВЛЯЮТСЯ</strong> одним лицом',
                    direct: 'column',
                    field: {
                        kinship: {
                            name: 'kinship',
                            label: 'Кем приходится налогоплательщику',
                            value: '',
                            // required: true,
                            type: 'select',
                            placeholder: 'Выбрать степень родства',
                            // help: 'супруг/супруга, сын/дочь, мать/отец',
                            items: [
                                {
                                    label: 'супруг / супруга',
                                    value: 1,
                                }, {
                                    label: 'сын / дочь',
                                    value: 2,
                                }, {
                                    label: 'мать / отец',
                                    value: 3,
                                }
                            ],
                            clear: true,
                            validators: {
                                // required: {
                                //     validator: required,
                                //     message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                                // },
                            },
                        },

                        name: {
                            name: 'name',
                            label: 'ФИО',
                            value: '',
                            required: false,
                            type: 'text',
                            clear: true,
                            validators: {
                                // required: {
                                //     validator: required,
                                //     message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                                // },
                            },
                        },

                        year_of_birth: {
                            name: 'year_of_birth',
                            label: 'Год рождения',
                            value: '',
                            required: false,
                            type: 'date',
                            clear: true,
                            validators: {
                                // required: {
                                //     validator: required,
                                //     message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                                // },
                            },
                        },

                        seria_pass: {
                            name: 'seria_pass',
                            label: 'Cерия и номер паспорта',
                            value: '',
                            required: false,
                            help: 'Пример: 61 23 234567',
                            type: 'text',
                            clear: true,
                            validators: {
                                // required: {
                                //     validator: required,
                                //     message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                                // },
                            },
                        },

                        year_pass: {
                            name: 'year_pass',
                            label: 'Дата выдачи паспорта',
                            value: '',
                            required: false,
                            type: 'date',
                            clear: true,
                            validators: {
                                // required: {
                                //     validator: required,
                                //     message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                                // },
                            },
                        },
                    }
                },
                {
                    label: 'Период, за который выдается справка\n',
                    direct: 'row',
                    field: {
                        period_start: {
                            name: 'period_start',
                            label: 'C',
                            value: '',
                            required: true,
                            type: 'date',
                            clear: true,
                            validators: {
                                required: {
                                    validator: required,
                                    message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                                },
                            },
                        },
                        period_end: {
                            name: 'period_end',
                            label: 'До',
                            value: '',
                            required: true,
                            type: 'date',
                            clear: true,
                            validators: {
                                required: {
                                    validator: required,
                                    message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                                },
                            },
                        },
                    },
                }, {
                    label: 'Контакты плательщика',
                    direct: 'column',
                    field: {
                        method: {
                            name: 'method',
                            label: 'Способ получения',
                            value: '',
                            placeholder: 'Выбрать способ получения',
                            required: true,
                            type: 'select',
                            clear: true,
                            items: [
                                {
                                    label: 'Выдать на руки лично',
                                    value: 1,
                                }, {
                                    label: 'Направить в электронной форме в налоговый орган',
                                    value: 2,
                                }, {
                                    label: 'Представителю по доверенности',
                                    value: 3,
                                },
                            ],
                            validators: {
                                required: {
                                    validator: required,
                                    message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                                },
                            },
                        },

                        phone: {
                            name: 'phone',
                            label: 'Номер телефона налогоплательщика',
                            value: '',
                            type: 'tel',
                            required: true,
                            clear: true,
                            mask: usePhoneMask,
                            validators: {
                                required: {
                                    validator: required,
                                    message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                                },
                                minLength: {
                                    validator: minLength(9),
                                    pattern: '',
                                    value: 9,
                                    message: useTemplate`${'label'} - Слишком короткое поле ${'length'} ${'value'}`,
                                },
                                maxLength: {
                                    validator: maxLength(20),
                                    pattern: '',
                                    value: 20,
                                    message: useTemplate`${'label'} - Слишком длинное поле ${'length'} ${'value'}`,
                                },
                            },
                        },

                        // email: {
                        //     name: 'email',
                        //     label: 'Email',
                        //     value: '',
                        //     type: 'email',
                        //     clear: true,
                        //     validators: {
                        //         required: {
                        //             validator: email,
                        //             message: useTemplate`Не верный формат ${'label'}`,
                        //         },
                        //     },
                        // },
                        //
                        // address: {
                        //     name: 'address',
                        //     label: 'Почтовый адрес',
                        //     value: '',
                        //     type: 'text',
                        //     clear: true,
                        //     validators: {
                        //         // required: {
                        //         //     validator: email,
                        //         //     message: useTemplate`Не верный формат ${'label'}`,
                        //         // },
                        //     },
                        // },
                    },
                }],
        })

        function reset() {
            Object.keys(form.fields).map((key) => {
                form.fields[key].value = ''
                form.fields[key].status = false;
            })
        }

        function click_btn() {
            // e.preventDefault();
        }

        return {
            click_btn,
            send,

            progress,
            date,
            data,
            status,
            form,

            onSubmit,
        }
    }
}
</script>

<style></style>

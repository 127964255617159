<template>
    <ul class="errors">
        <li class="errors__item" v-if="typeof errors === 'string'">{{ errors }}</li>
        <template v-else>
            <transition-group name="fade">
                <li v-for="(error, index) in errors"
                    class="errors__item"
                    :key="index">
                    <small class="field__error">{{error.message}}</small>
                </li>
            </transition-group>
        </template>
    </ul>
</template>

<script>
    import {computed} from 'vue'

    export default {
        name: "Errors",
        props: {
            errors: Object,
        },
        setup(props) {
            let is_show = computed(() => {
                return props.errors
            })

            return {is_show}
        }
    }
</script>

<style lang="less">
    .errors {
        font-size: var(--small) !important;
        outline: none;
        list-style-type: none;
        margin: 0;

        &__item {
            padding: .5rem;
            color: var(--danger);
        }
    }
</style>

import {computed, reactive} from 'vue'
import useField from "@use/field";

/**
 *
 * @param init: Object
 * @returns {UnwrapNestedRefs<{}>}
 *
 Пример объекта:
 для создание и валидации полей формы

let form = {
    captcha: true,
    title: null,
    description: null,
    field: {
        name: {
            name: 'name',
            label: 'Email',
            value: '',
            type: 'text',
            clear: true,
            help: 'контактная электронная почта',
            validators: {
                required: {
                    validator: required,
                    message: useTemplate`${'name'} Обязательное поле для заполнения `,
                },
                minLength: {
                    validator: minLength(5),
                    pattern: '',
                    value: 5,
                    message: useTemplate`${'name'} Слишком короткое поле ${'length'} ${'value'}`,
                },
            },
        },
    }
}
 */

let id = 0;

export default function useForm(init = {}) {
    const form = reactive({});
    form.id = id++;
    form.compact = init.compact;
    form.title = init.title;
    form.description = init.description;

    form.fields = reactive({});
    form.groups = reactive([]);
    form.errors = reactive([]);

    if (init?.groups) {
        for (const group of init.groups) {
            const item = {
                label: group.label,
                help: group.help,
                description: group.description,
                direct: group.direct,
                keys: []
            }

            for (const [key, value] of Object.entries(group.field)) {
                form.fields[key] = useField(value);
                item.keys.push(key);
            }

            form.groups.push(item);
        }
    }

    if (init.field) {
        for (const [key, value] of Object.entries(init.field)) {
            form.fields[key] = useField(value);
        }
    }

    form.addError = (message) => {
        form.errors = [{message}, ...form.errors]
    }

    form.clearErrors = () => {
        form.errors = []
    }

    form.each = (fn) => {
        Object.keys(form.fields ?? {}).map((key) => {
            fn(form.fields[key], key)
        });
    }

    form.fill = (value) => {
        form.each((item, key) => {
            if (value) {
                item.value = value?.[key] ?? '';
            } else {
                item.value = value?.[key] ?? '';
            }
        })
    }

    form.clear = () => {
        form.clearErrors();
        form.each((item) => {
            item.value = '';
            item.status = false;
            // item.valid = false;
        })
    }

    form.get_data = () => {
        let data = {}
        form.each((item, key) => {
            data[key] = item.value
        })

        return data
    }

    form.valid = computed(() => {
        return Object.keys(form.fields)
            .filter((key) => key !== 'valid')
            .reduce((acc, key) => {
                return acc = acc && form.fields[key].valid
            }, true)
    })

    return form
}
